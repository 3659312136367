import { StarFilled, StarOutlined } from '@ant-design/icons';
import {
  EventNotificationFrequencies,
  EventNotificationFrequencyWeekly,
  WaterbodyDetail,
} from '@omniafishing/core';
import React from 'react';
import { useResponsive } from '../../hooks/use_responsive';
import { useUserWaterbodies } from '../../hooks/use_user_waterbodies';
import { OmniaButton } from '../omnia_button/omnia_button';
import { WaterbodyDetailFollowTooltip } from '../waterbody_detail/waterbody_detail_sections/waterbody_detail_follow/waterbody_detail_follow_tooltip';
import styles from './waterbody_follow_button_logged_in.less';

interface WaterbodyFollowButtonLoggedInProps {
  waterbody: WaterbodyDetail;
  microMobileButton?: boolean;
  alwaysMicroButton?: boolean;
  onClick?: () => void;
  className?: string;
  buttonType?: 'primary' | 'tertiary';
  buttonSize?: 'sm' | 'md' | 'lg';
  iconSize?: number;
  blockButton?: boolean;
  noPopup?: boolean;
}

export const WaterbodyFollowButtonLoggedIn = (props: WaterbodyFollowButtonLoggedInProps) => {
  const {
    alwaysMicroButton,
    microMobileButton,
    onClick,
    waterbody,
    className,
    buttonType = 'primary',
    iconSize = '15px',
    buttonSize = 'lg',
    blockButton,
    noPopup,
  } = props;

  const { isMobile } = useResponsive();

  const { isWaterbodyInUserWaterbodies } = useUserWaterbodies();
  const { unfavoriteWaterbody, favoriteWaterbody } = useUserWaterbodies();

  const isFollowed = isWaterbodyInUserWaterbodies(waterbody);

  const event_notifications = {
    new_fishing_report: true,
    new_article: true,
    frequency: EventNotificationFrequencyWeekly as EventNotificationFrequencies,
  };

  const squareButton = (isMobile && microMobileButton) || alwaysMicroButton;

  return (
    <div className={className}>
      {isFollowed ? (
        <OmniaButton
          onClick={() => {
            unfavoriteWaterbody.mutate(waterbody.url_slug);
            onClick?.();
          }}
          size={buttonSize}
          kind={buttonType}
          fontSize={14}
          className={styles.followButton}
          shape={squareButton ? 'square' : 'rectangle'}
          block={blockButton}
        >
          <StarFilled style={{ fontSize: iconSize, width: iconSize }} />{' '}
          {squareButton ? '' : 'Unfollow'}
        </OmniaButton>
      ) : noPopup ? (
        <OmniaButton
          onClick={() => {
            favoriteWaterbody.mutate({ event_notifications, url_slug: waterbody.url_slug });
            onClick?.();
          }}
          block={blockButton}
          size="lg"
          kind={buttonType}
          fontSize={14}
          className={styles.followButton}
          shape={(isMobile && microMobileButton) || alwaysMicroButton ? 'square' : 'rectangle'}
        >
          <StarOutlined style={{ fontSize: iconSize, width: iconSize }} />
          {(isMobile && microMobileButton) || alwaysMicroButton ? '' : 'Follow'}
        </OmniaButton>
      ) : (
        <WaterbodyDetailFollowTooltip waterbody={waterbody}>
          <OmniaButton
            onClick={onClick}
            block={blockButton}
            size="lg"
            kind={buttonType}
            fontSize={14}
            className={styles.followButton}
            shape={(isMobile && microMobileButton) || alwaysMicroButton ? 'square' : 'rectangle'}
          >
            <StarOutlined style={{ fontSize: iconSize, width: iconSize }} />
            {(isMobile && microMobileButton) || alwaysMicroButton ? '' : 'Follow'}
          </OmniaButton>
        </WaterbodyDetailFollowTooltip>
      )}
    </div>
  );
};
