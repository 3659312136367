import { useSelector } from 'react-redux';
import { getUser } from '../redux/user';

export const useUser = () => {
  const user = useSelector(getUser);
  const isPremium = !!user?.premium;
  const isPro = !!user?.premium_pro;
  const isAdmin = !!user?.admin;
  const isVerified = !!user?.verified;

  return {
    user,
    isPremium,
    isPro,
    isAdmin,
    isVerified,
  };
};
