import {
  FetchRecommendationFamiliesResponse,
  Product,
  RecommendationsParams,
  RecommendedProductFamily,
  RecommendedProductFamilyFacets,
} from '@omniafishing/core';
import { RequestThunk } from '../../types/generic';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { apiV1 } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import getNow from '../lib/time';
import { ActionsUnion, createAction } from './actions_helper';
import { UserActions } from './user';

export const reducerName = 'recommendations';

export enum StateKeys {
  facetProducts = 'facetProducts',
  facetTimestamp = 'facetTimestamp',
  products = 'products',
  productsTimestamp = 'productsTimestamp',
  loadingState = 'loadingState',
  productFamilies = 'productFamilies',
  productFamiliesFacets = 'productFamiliesFacets',
  productFamiliesTotalResults = 'productFamiliesTotalResults',
  recommendationId = 'recommendationId',
}

export const initialState = {
  [StateKeys.loadingState]: LoadingState.NOT_STARTED,
  [StateKeys.products]: [] as Product[],
  [StateKeys.productsTimestamp]: 0,
  [StateKeys.facetProducts]: [] as Product[],
  [StateKeys.facetTimestamp]: 0,
  [StateKeys.recommendationId]: null as string,
  [StateKeys.productFamilies]: [] as RecommendedProductFamily[],
  [StateKeys.productFamiliesTotalResults]: 0,
  [StateKeys.productFamiliesFacets]: {
    brands: {},
    subcategories: {},
    subcat_types: {},
  } as RecommendedProductFamilyFacets,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.loadingState];
export const getProducts = (state: ApplicationState) => state[reducerName][StateKeys.products];
export const getFacetProducts = (state: ApplicationState) =>
  state[reducerName][StateKeys.facetProducts];
export const getProductFamilies = (state: ApplicationState) =>
  state[reducerName][StateKeys.productFamilies];
export const getProductFamiliesFacets = (state: ApplicationState) =>
  state[reducerName][StateKeys.productFamiliesFacets];
export const getProductFamiliesTotalResults = (state: ApplicationState) =>
  state[reducerName][StateKeys.productFamiliesTotalResults];
export const getRecommendationId = (state: ApplicationState) =>
  state[reducerName][StateKeys.recommendationId];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function productsReducer(
  state = initialState,
  action: RecommendationsActions | UserActions
) {
  switch (action.type) {
    //
    // Families
    //

    case ReduxActions.RECOMMENDATIONS_FAMILIES_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.PENDING,
        [StateKeys.productsTimestamp]: action.payload.metadata.timestamp,
        [StateKeys.recommendationId]: null,
      };

    case ReduxActions.RECOMMENDATIONS_FAMILIES_FETCH_SUCCESS:
      if (action.payload.metadata.timestamp < state[StateKeys.productsTimestamp]) {
        return state;
      }
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.DONE,
        [StateKeys.productFamilies]: action.payload.data,
        [StateKeys.productFamiliesTotalResults]: action.payload.metadata.total_results,
        [StateKeys.recommendationId]: action.payload.metadata.recommendation_id,
      };

    case ReduxActions.RECOMMENDATIONS_FAMILIES_FACETS_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.facetTimestamp]: action.payload.timestamp,
      };

    case ReduxActions.RECOMMENDATIONS_FAMILIES_FACETS_FETCH_SUCCESS:
      if (action.payload.metadata.timestamp < state[StateKeys.facetTimestamp]) {
        return state;
      }
      return {
        ...state,
        [StateKeys.productFamiliesFacets]: action.payload.metadata.facets,
      };

    //
    // Reset
    //

    case ReduxActions.RECOMMENDATIONS_RESET:
      return initialState;

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const RecommendationsActions = {
  RECOMMENDATIONS_FAMILIES_FETCH_PENDING: (timestamp: number) =>
    createAction(ReduxActions.RECOMMENDATIONS_FAMILIES_FETCH_PENDING, { metadata: { timestamp } }),
  RECOMMENDATIONS_FAMILIES_FETCH_SUCCESS: (response: FetchRecommendationFamiliesResponse) =>
    createAction(ReduxActions.RECOMMENDATIONS_FAMILIES_FETCH_SUCCESS, response),
  RECOMMENDATIONS_FAMILIES_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.RECOMMENDATIONS_FAMILIES_FETCH_ERROR, err),

  RECOMMENDATIONS_FAMILIES_FACETS_FETCH_PENDING: (timestamp: number) =>
    createAction(ReduxActions.RECOMMENDATIONS_FAMILIES_FACETS_FETCH_PENDING, { timestamp }),
  RECOMMENDATIONS_FAMILIES_FACETS_FETCH_SUCCESS: (response: FetchRecommendationFamiliesResponse) =>
    createAction(ReduxActions.RECOMMENDATIONS_FAMILIES_FACETS_FETCH_SUCCESS, response),
  RECOMMENDATIONS_FAMILIES_FACETS_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.RECOMMENDATIONS_FAMILIES_FACETS_FETCH_ERROR, err),

  RECOMMENDATIONS_RESET: () => createAction(ReduxActions.RECOMMENDATIONS_RESET),
};
export type RecommendationsActions = ActionsUnion<typeof RecommendationsActions>;

export const CATEGORY_PAGE_LIMIT_DEFAULT = 48;

export function fetchRecommendationFamilies(params: RecommendationsParams): RequestThunk {
  return (dispatch) => {
    const now = getNow();

    dispatch(RecommendationsActions.RECOMMENDATIONS_FAMILIES_FETCH_PENDING(now));

    return apiV1
      .recommendationsFetch(params)
      .then((response) => {
        return dispatch(
          RecommendationsActions.RECOMMENDATIONS_FAMILIES_FETCH_SUCCESS({
            ...response.data,
            metadata: {
              ...response.data.metadata,
              timestamp: now,
            },
          })
        );
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchRecommendationFamilies: ${JSON.stringify(params)}`, error);
        return dispatch(RecommendationsActions.RECOMMENDATIONS_FAMILIES_FETCH_ERROR(error));
      });
  };
}

export function fetchRecommendationFamiliesFacets(
  params: Pick<
    RecommendationsParams,
    | 'styles'
    | 'species'
    | 'seasons'
    | 'clarities'
    | 'subcategories'
    | 'subcat_types'
    | 'brands'
    | 'include_hidden_products'
  >
): RequestThunk {
  return (dispatch) => {
    const now = getNow();

    dispatch(RecommendationsActions.RECOMMENDATIONS_FAMILIES_FACETS_FETCH_PENDING(now));

    return apiV1
      .recommendationsFetch(params)
      .then((response) => {
        return dispatch(
          RecommendationsActions.RECOMMENDATIONS_FAMILIES_FACETS_FETCH_SUCCESS({
            ...response.data,
            metadata: {
              ...response.data.metadata,
              timestamp: now,
            },
          })
        );
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchRecommendationFamiliesFacets: ${JSON.stringify(params)}`, error);
        return dispatch(RecommendationsActions.RECOMMENDATIONS_FAMILIES_FACETS_FETCH_ERROR(error));
      });
  };
}
