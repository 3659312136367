export enum ReduxActions {
  AFFILIATE_FETCH_ERROR = 'AFFILIATE_FETCH_ERROR',
  AFFILIATE_FETCH_PENDING = 'AFFILIATE_FETCH_PENDING',
  AFFILIATE_FETCH_SUCCESS = 'AFFILIATE_FETCH_SUCCESS',

  AMBASSADORS_FETCH_ERROR = 'AMBASSADORS_FETCH_ERROR',
  AMBASSADORS_FETCH_PENDING = 'AMBASSADORS_FETCH_PENDING',
  AMBASSADORS_FETCH_SUCCESS = 'AMBASSADORS_FETCH_SUCCESS',

  ARTICLE_FETCH_ERROR = 'ARTICLE_FETCH_ERROR',
  ARTICLE_FETCH_PENDING = 'ARTICLE_FETCH_PENDING',
  ARTICLE_FETCH_SUCCESS = 'ARTICLE_FETCH_SUCCESS',

  ARTICLE_DATA_FETCH_ERROR = 'ARTICLE_DATA_FETCH_ERROR',
  ARTICLE_DATA_FETCH_PENDING = 'ARTICLE_DATA_FETCH_PENDING',
  ARTICLE_DATA_FETCH_SUCCESS = 'ARTICLE_DATA_FETCH_SUCCESS',

  ARTICLE_RELATED_CONTENT_FETCH_ERROR = 'ARTICLE_RELATED_CONTENT_FETCH_ERROR',
  ARTICLE_RELATED_CONTENT_FETCH_PENDING = 'ARTICLE_RELATED_CONTENT_FETCH_PENDING',
  ARTICLE_RELATED_CONTENT_FETCH_SUCCESS = 'ARTICLE_RELATED_CONTENT_FETCH_SUCCESS',

  AUTH_CONFIRM_ERROR = 'AUTH_CONFIRM_ERROR',
  AUTH_CONFIRM_PENDING = 'AUTH_CONFIRM_PENDING',
  AUTH_CONFIRM_SUCCESS = 'AUTH_CONFIRM_SUCCESS',
  AUTH_EXPIRED_CHECK = 'AUTH_EXPIRED_CHECK',
  AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR',
  AUTH_LOGIN_PENDING = 'AUTH_LOGIN_PENDING',
  AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS',
  AUTH_LOGOUT = 'AUTH_LOGOUT',
  AUTH_PASSWORD_RESET_CONFIRM_ERROR = 'AUTH_PASSWORD_RESET_CONFIRM_ERROR',
  AUTH_PASSWORD_RESET_CONFIRM_PENDING = 'AUTH_PASSWORD_RESET_CONFIRM_PENDING',
  AUTH_PASSWORD_RESET_CONFIRM_SUCCESS = 'AUTH_PASSWORD_RESET_CONFIRM_SUCCESS',
  AUTH_REFRESH_ERROR = 'AUTH_REFRESH_ERROR',
  AUTH_REFRESH_PENDING = 'AUTH_REFRESH_PENDING',
  AUTH_REFRESH_SUCCESS = 'AUTH_REFRESH_SUCCESS',
  AUTH_SIGNUP_ERROR = 'AUTH_SIGNUP_ERROR',
  AUTH_SIGNUP_PENDING = 'AUTH_SIGNUP_PENDING',
  AUTH_SIGNUP_SUCCESS = 'AUTH_SIGNUP_SUCCESS',

  BEST_SELLERS_FETCH_ERROR = 'BEST_SELLERS_FETCH_ERROR',
  BEST_SELLERS_FETCH_PENDING = 'BEST_SELLERS_FETCH_PENDING',
  BEST_SELLERS_FETCH_SUCCESS = 'BEST_SELLERS_FETCH_SUCCESS',

  BRAND_FETCH_ERROR = 'BRAND_FETCH_ERROR',
  BRAND_FETCH_PENDING = 'BRAND_FETCH_PENDING',
  BRAND_FETCH_SUCCESS = 'BRAND_FETCH_SUCCESS',
  BRAND_AMBASSADORS_FETCH_ERROR = 'BRAND_AMBASSADORS_FETCH_ERROR',
  BRAND_AMBASSADORS_FETCH_PENDING = 'BRAND_AMBASSADORS_FETCH_PENDING',
  BRAND_AMBASSADORS_FETCH_SUCCESS = 'BRAND_AMBASSADORS_FETCH_SUCCESS',
  BRAND_FAVORITE_ERROR = 'BRAND_FAVORITE_ERROR',
  BRAND_FAVORITE_PENDING = 'BRAND_FAVORITE_PENDING',
  BRAND_FAVORITE_SUCCESS = 'BRAND_FAVORITE_SUCCESS',
  BRAND_UNFAVORITE_ERROR = 'BRAND_UNFAVORITE_ERROR',
  BRAND_UNFAVORITE_PENDING = 'BRAND_UNFAVORITE_PENDING',
  BRAND_UNFAVORITE_SUCCESS = 'BRAND_UNFAVORITE_SUCCESS',
  BRAND_EVENT_NOTIFICATIONS_UPDATE_ERROR = 'BRAND_EVENT_NOTIFICATIONS_UPDATE_ERROR',
  BRAND_EVENT_NOTIFICATIONS_UPDATE_PENDING = 'BRAND_EVENT_NOTIFICATIONS_UPDATE_PENDING',
  BRAND_EVENT_NOTIFICATIONS_UPDATE_SUCCESS = 'BRAND_EVENT_NOTIFICATIONS_UPDATE_SUCCESS',

  CART_ATTRIBUTES_UPDATE_ERROR = 'CART_ATTRIBUTES_UPDATE_ERROR',
  CART_ATTRIBUTES_UPDATE_PENDING = 'CART_ATTRIBUTES_UPDATE_PENDING',
  CART_ATTRIBUTES_UPDATE_SUCCESS = 'CART_ATTRIBUTES_UPDATE_SUCCESS',
  CART_CLOSE = 'CART_CLOSE',
  CART_DISCOUNT_APPLY_ERROR = 'CART_DISCOUNT_APPLY_ERROR',
  CART_DISCOUNT_APPLY_PENDING = 'CART_DISCOUNT_APPLY_PENDING',
  CART_DISCOUNT_APPLY_SUCCESS = 'CART_DISCOUNT_APPLY_SUCCESS',
  CART_DISCOUNT_REMOVE_ERROR = 'CART_DISCOUNT_REMOVE_ERROR',
  CART_DISCOUNT_REMOVE_PENDING = 'CART_DISCOUNT_REMOVE_PENDING',
  CART_DISCOUNT_REMOVE_SUCCESS = 'CART_DISCOUNT_REMOVE_SUCCESS',
  CART_FETCH_ERROR = 'CART_FETCH_ERROR',
  CART_FETCH_PENDING = 'CART_FETCH_PENDING',
  CART_FETCH_SUCCESS = 'CART_FETCH_SUCCESS',
  CART_GIFT_CARD_REMOVE_ERROR = 'CART_GIFT_CARD_REMOVE_ERROR',
  CART_GIFT_CARD_REMOVE_PENDING = 'CART_GIFT_CARD_REMOVE_PENDING',
  CART_GIFT_CARD_REMOVE_SUCCESS = 'CART_GIFT_CARD_REMOVE_SUCCESS',
  CART_HEADER_BANNER_APPEND = 'CART_HEADER_BANNER_APPEND',
  CART_HEADER_BANNER_CLEAR = 'CART_HEADER_BANNER_CLEAR',
  CART_ID_FETCH_ERROR = 'CART_ID_FETCH_ERROR',
  CART_ID_FETCH_PENDING = 'CART_ID_FETCH_PENDING',
  CART_ID_FETCH_SUCCESS = 'CART_ID_FETCH_SUCCESS',
  CART_ITEM_ADD_ERROR = 'CART_ITEM_ADD_ERROR',
  CART_ITEM_ADD_PENDING = 'CART_ITEM_ADD_PENDING',
  CART_ITEM_ADD_SUCCESS = 'CART_ITEM_ADD_SUCCESS',
  CART_ITEM_REMOVE_ERROR = 'CART_ITEM_REMOVE_ERROR',
  CART_ITEM_REMOVE_PENDING = 'CART_ITEM_REMOVE_PENDING',
  CART_ITEM_REMOVE_SUCCESS = 'CART_ITEM_REMOVE_SUCCESS',
  CART_ITEM_UPDATE_ERROR = 'CART_ITEM_UPDATE_ERROR',
  CART_ITEM_UPDATE_PENDING = 'CART_ITEM_UPDATE_PENDING',
  CART_ITEM_UPDATE_SUCCESS = 'CART_ITEM_UPDATE_SUCCESS',
  CART_OPEN = 'CART_OPEN',
  CART_PROMOS_FETCH_ERROR = 'CART_PROMOS_FETCH_ERROR',
  CART_PROMOS_FETCH_PENDING = 'CART_PROMOS_FETCH_PENDING',
  CART_PROMOS_FETCH_SUCCESS = 'CART_PROMOS_FETCH_SUCCESS',
  CART_REBATE_APPLY_ERROR = 'CART_REBATE_APPLY_ERROR',
  CART_REBATE_APPLY_PENDING = 'CART_REBATE_APPLY_PENDING',
  CART_REBATE_APPLY_SUCCESS = 'CART_REBATE_APPLY_SUCCESS',
  CART_REBATE_FETCH_ERROR = 'CART_REBATE_FETCH_ERROR',
  CART_REBATE_FETCH_PENDING = 'CART_REBATE_FETCH_PENDING',
  CART_REBATE_FETCH_SUCCESS = 'CART_REBATE_FETCH_SUCCESS',
  CART_UPSELL_PRODUCTS_FETCH_ERROR = 'CART_UPSELL_PRODUCTS_FETCH_ERROR',
  CART_UPSELL_PRODUCTS_FETCH_PENDING = 'CART_UPSELL_PRODUCTS_FETCH_PENDING',
  CART_UPSELL_PRODUCTS_FETCH_SUCCESS = 'CART_UPSELL_PRODUCTS_FETCH_SUCCESS',

  CATEGORY_BRANDS_FETCH_ERROR = 'CATEGORY_BRANDS_FETCH_ERROR',
  CATEGORY_BRANDS_FETCH_PENDING = 'CATEGORY_BRANDS_FETCH_PENDING',
  CATEGORY_BRANDS_FETCH_SUCCESS = 'CATEGORY_BRANDS_FETCH_SUCCESS',

  CATEGORIES_FETCH_ERROR = 'CATEGORIES_FETCH_ERROR',
  CATEGORIES_FETCH_PENDING = 'CATEGORIES_FETCH_PENDING',
  CATEGORIES_FETCH_SUCCESS = 'CATEGORIES_FETCH_SUCCESS',

  DEALS_FETCH_ERROR = 'DEALS_FETCH_ERROR',
  DEALS_FETCH_PENDING = 'DEALS_FETCH_PENDING',
  DEALS_FETCH_SUCCESS = 'DEALS_FETCH_SUCCESS',

  EVENT_NOTIFICATION_DESTROY_ERROR = 'EVENT_NOTIFICATION_DESTROY_ERROR',
  EVENT_NOTIFICATION_DESTROY_PENDING = 'EVENT_NOTIFICATION_DESTROY_PENDING',
  EVENT_NOTIFICATION_DESTROY_SUCCESS = 'EVENT_NOTIFICATION_DESTROY_SUCCESS',
  EVENT_NOTIFICATION_SUBSCRIBE_ERROR = 'EVENT_NOTIFICATION_SUBSCRIBE_ERROR',
  EVENT_NOTIFICATION_SUBSCRIBE_PENDING = 'EVENT_NOTIFICATION_SUBSCRIBE_PENDING',
  EVENT_NOTIFICATION_SUBSCRIBE_SUCCESS = 'EVENT_NOTIFICATION_SUBSCRIBE_SUCCESS',

  FISHING_REPORT_FETCH_ERROR = 'FISHING_REPORT_FETCH_ERROR',
  FISHING_REPORT_FETCH_PENDING = 'FISHING_REPORT_FETCH_PENDING',
  FISHING_REPORT_FETCH_SUCCESS = 'FISHING_REPORT_FETCH_SUCCESS',
  FISHING_REPORTS_FETCH_ERROR = 'FISHING_REPORTS_FETCH_ERROR',
  FISHING_REPORTS_FETCH_PENDING = 'FISHING_REPORTS_FETCH_PENDING',
  FISHING_REPORTS_FETCH_SUCCESS = 'FISHING_REPORTS_FETCH_SUCCESS',
  FISHING_REPORT_MODAL_CLOSE = 'FISHING_REPORT_MODAL_CLOSE',
  FISHING_REPORT_MODAL_OPEN = 'FISHING_REPORT_MODAL_OPEN',
  FISHING_REPORTS_USERS_FETCH_ERROR = 'FISHING_REPORTS_USERS_FETCH_ERROR',
  FISHING_REPORTS_USERS_FETCH_PENDING = 'FISHING_REPORTS_USERS_FETCH_PENDING',
  FISHING_REPORTS_USERS_FETCH_SUCCESS = 'FISHING_REPORTS_USERS_FETCH_SUCCESS',

  FLASH_MESSAGE_SET = 'FLASH_MESSAGE_SET',
  FLASH_MESSAGE_CLEAR = 'FLASH_MESSAGE_CLEAR',

  GENERIC_PAGE_DATA_FETCH_ERROR = 'GENERIC_PAGE_DATA_FETCH_ERROR',
  GENERIC_PAGE_DATA_FETCH_PENDING = 'GENERIC_PAGE_DATA_FETCH_PENDING',
  GENERIC_PAGE_DATA_FETCH_SUCCESS = 'GENERIC_PAGE_DATA_FETCH_SUCCESS',

  INVENTORY_MODAL_CLOSE = 'INVENTORY_MODAL_CLOSE',
  INVENTORY_MODAL_OPEN = 'INVENTORY_MODAL_OPEN',
  INVENTORY_MODAL_SUBSCRIBE_ERROR = 'INVENTORY_MODAL_SUBSCRIBE_ERROR',
  INVENTORY_MODAL_SUBSCRIBE_PENDING = 'INVENTORY_MODAL_SUBSCRIBE_PENDING',
  INVENTORY_MODAL_SUBSCRIBE_SUCCESS = 'INVENTORY_MODAL_SUBSCRIBE_SUCCESS',

  LANDING_PAGE_FETCH_ERROR = 'LANDING_PAGE_FETCH_ERROR',
  LANDING_PAGE_FETCH_PENDING = 'LANDING_PAGE_FETCH_PENDING',
  LANDING_PAGE_FETCH_SUCCESS = 'LANDING_PAGE_FETCH_SUCCESS',

  LIST_FETCH_ERROR = 'LIST_FETCH_ERROR',
  LIST_FETCH_PENDING = 'LIST_FETCH_PENDING',
  LIST_FETCH_SUCCESS = 'LIST_FETCH_SUCCESS',
  LIST_CLEAR = 'LIST_CLEAR',

  LOCATION_FETCH_PENDING = 'LOCATION_FETCH_PENDING',
  LOCATION_FETCH_SUCCESS = 'LOCATION_FETCH_SUCCESS',
  LOCATION_FETCH_ERROR = 'LOCATION_FETCH_ERROR',
  LOCATION_UPDATE = 'LOCATION_UPDATE',

  NEWS_ALL_FETCH_ERROR = 'NEWS_ALL_FETCH_ERROR',
  NEWS_ALL_FETCH_PENDING = 'NEWS_ALL_FETCH_PENDING',
  NEWS_ALL_FETCH_SUCCESS = 'NEWS_ALL_FETCH_SUCCESS',
  NEWS_FETCH_ERROR = 'NEWS_FETCH_ERROR',
  NEWS_FETCH_PENDING = 'NEWS_FETCH_PENDING',
  NEWS_FETCH_SUCCESS = 'NEWS_FETCH_SUCCESS',

  OMNIA_VIDEO_FETCH_ERROR = 'OMNIA_VIDEO_FETCH_ERROR',
  OMNIA_VIDEO_FETCH_PENDING = 'OMNIA_VIDEO_FETCH_PENDING',
  OMNIA_VIDEO_FETCH_SUCCESS = 'OMNIA_VIDEO_FETCH_SUCCESS',
  OMNIA_VIDEOS_FETCH_ERROR = 'OMNIA_VIDEOS_FETCH_ERROR',
  OMNIA_VIDEOS_FETCH_PENDING = 'OMNIA_VIDEOS_FETCH_PENDING',
  OMNIA_VIDEOS_FETCH_SUCCESS = 'OMNIA_VIDEOS_FETCH_SUCCESS',

  ORGANIZATIONS_FETCH_ERROR = 'ORGANIZATIONS_FETCH_ERROR',
  ORGANIZATIONS_FETCH_PENDING = 'ORGANIZATIONS_FETCH_PENDING',
  ORGANIZATIONS_FETCH_SUCCESS = 'ORGANIZATIONS_FETCH_SUCCESS',
  ORGANIZATION_FETCH_ERROR = 'ORGANIZATION_FETCH_ERROR',
  ORGANIZATION_FETCH_PENDING = 'ORGANIZATION_FETCH_PENDING',
  ORGANIZATION_FETCH_SUCCESS = 'ORGANIZATION_FETCH_SUCCESS',
  ORGANIZATION_JOIN_ERROR = 'ORGANIZATION_JOIN_ERROR',
  ORGANIZATION_JOIN_PENDING = 'ORGANIZATION_JOIN_PENDING',
  ORGANIZATION_JOIN_SUCCESS = 'ORGANIZATION_JOIN_SUCCESS',
  ORGANIZATION_LEAVE_ERROR = 'ORGANIZATION_LEAVE_ERROR',
  ORGANIZATION_LEAVE_PENDING = 'ORGANIZATION_LEAVE_PENDING',
  ORGANIZATION_LEAVE_SUCCESS = 'ORGANIZATION_LEAVE_SUCCESS',
  ORGANIZATION_FETCH_FISHING_REPORTS_ERROR = 'ORGANIZATION_FETCH_FISHING_REPORTS_ERROR',
  ORGANIZATION_FETCH_FISHING_REPORTS_PENDING = 'ORGANIZATION_FETCH_FISHING_REPORTS_PENDING',
  ORGANIZATION_FETCH_FISHING_REPORTS_SUCCESS = 'ORGANIZATION_FETCH_FISHING_REPORTS_SUCCESS',

  PAGE_FETCH_ERROR = 'PAGE_FETCH_ERROR',
  PAGE_FETCH_PENDING = 'PAGE_FETCH_PENDING',
  PAGE_FETCH_SUCCESS = 'PAGE_FETCH_SUCCESS',

  PRISMIC_PAGE_FETCH_ERROR = 'PRISMIC_PAGE_FETCH_ERROR',
  PRISMIC_PAGE_FETCH_PENDING = 'PRISMIC_PAGE_FETCH_PENDING',
  PRISMIC_PAGE_FETCH_SUCCESS = 'PRISMIC_PAGE_FETCH_SUCCESS',

  PRISMIC_QAS_FETCH_ERROR = 'PRISMIC_QAS_FETCH_ERROR',
  PRISMIC_QAS_FETCH_PENDING = 'PRISMIC_QAS_FETCH_PENDING',
  PRISMIC_QAS_FETCH_SUCCESS = 'PRISMIC_QAS_FETCH_SUCCESS',

  PRODUCT_DETAIL_CLEAR = 'PRODUCT_DETAIL_CLEAR',

  PRODUCT_FAMILY_FETCH_ERROR = 'PRODUCT_FAMILY_FETCH_ERROR',
  PRODUCT_FAMILY_FETCH_PENDING = 'PRODUCT_FAMILY_FETCH_PENDING',
  PRODUCT_FAMILY_FETCH_SUCCESS = 'PRODUCT_FAMILY_FETCH_SUCCESS',
  PRODUCT_FAMILY_FISHING_REPORTS_FETCH_ERROR = 'PRODUCT_FAMILY_FISHING_REPORTS_FETCH_ERROR',
  PRODUCT_FAMILY_FISHING_REPORTS_FETCH_PENDING = 'PRODUCT_FAMILY_FISHING_REPORTS_FETCH_PENDING',
  PRODUCT_FAMILY_FISHING_REPORTS_FETCH_SUCCESS = 'PRODUCT_FAMILY_FISHING_REPORTS_FETCH_SUCCESS',

  PRODUCT_FAMILY_QUICK_MODAL_OPEN = 'PRODUCT_FAMILY_QUICK_MODAL_OPEN',
  PRODUCT_FAMILY_QUICK_MODAL_CLEAR = 'PRODUCT_FAMILY_QUICK_MODAL_CLEAR',
  PRODUCT_FAMILY_QUICK_MODAL_CLOSE = 'PRODUCT_FAMILY_QUICK_MODAL_CLOSE',

  PRODUCTS_FETCH_ERROR = 'PRODUCTS_FETCH_ERROR',
  PRODUCTS_FETCH_PENDING = 'PRODUCTS_FETCH_PENDING',
  PRODUCTS_FETCH_SUCCESS = 'PRODUCTS_FETCH_SUCCESS',
  PRODUCTS_FACETS_ERROR = 'PRODUCTS_FACETS_ERROR',
  PRODUCTS_FACETS_PENDING = 'PRODUCTS_FACETS_PENDING',
  PRODUCTS_FACETS_SUCCESS = 'PRODUCTS_FACETS_SUCCESS',
  PRODUCTS_RESET = 'PRODUCTS_RESET',
  PRODUCT_FISHING_REPORTS_FETCH_ERROR = 'PRODUCT_FISHING_REPORTS_FETCH_ERROR',
  PRODUCT_FISHING_REPORTS_FETCH_PENDING = 'PRODUCT_FISHING_REPORTS_FETCH_PENDING',
  PRODUCT_FISHING_REPORTS_FETCH_SUCCESS = 'PRODUCT_FISHING_REPORTS_FETCH_SUCCESS',
  PRODUCT_FISHING_REPORTS_NEARBY_FETCH_ERROR = 'PRODUCT_FISHING_REPORTS_NEARBY_FETCH_ERROR',
  PRODUCT_FISHING_REPORTS_NEARBY_FETCH_PENDING = 'PRODUCT_FISHING_REPORTS_NEARBY_FETCH_PENDING',
  PRODUCT_FISHING_REPORTS_NEARBY_FETCH_SUCCESS = 'PRODUCT_FISHING_REPORTS_NEARBY_FETCH_SUCCESS',

  PRODUCTS_TAGGED_FETCH_ERROR = 'PRODUCTS_TAGGED_FETCH_ERROR',
  PRODUCTS_TAGGED_FETCH_PENDING = 'PRODUCTS_TAGGED_FETCH_PENDING',
  PRODUCTS_TAGGED_FETCH_SUCCESS = 'PRODUCTS_TAGGED_FETCH_SUCCESS',

  PRODUCT_FAMILIES_TAGGED_FETCH_ERROR = 'PRODUCT_FAMILIES_TAGGED_FETCH_ERROR',
  PRODUCT_FAMILIES_TAGGED_FETCH_PENDING = 'PRODUCT_FAMILIES_TAGGED_FETCH_PENDING',
  PRODUCT_FAMILIES_TAGGED_FETCH_SUCCESS = 'PRODUCT_FAMILIES_TAGGED_FETCH_SUCCESS',

  PRO_UPSELL_MODAL_OPEN = 'PRO_UPSELL_MODAL_OPEN',
  PRO_UPSELL_MODAL_CLOSE = 'PRO_UPSELL_MODAL_CLOSE',

  ROUTER_LOCATION_CHANGE = 'ROUTER_LOCATION_CHANGE',
  ROUTER_LOADED = 'ROUTER_LOADED',

  RECOMMENDATIONS_RESET = 'RECOMMENDATIONS_RESET',
  RECOMMENDATIONS_FAMILIES_FETCH_ERROR = 'RECOMMENDATIONS_FAMILIES_FETCH_ERROR',
  RECOMMENDATIONS_FAMILIES_FETCH_PENDING = 'RECOMMENDATIONS_FAMILIES_FETCH_PENDING',
  RECOMMENDATIONS_FAMILIES_FETCH_SUCCESS = 'RECOMMENDATIONS_FAMILIES_FETCH_SUCCESS',
  RECOMMENDATIONS_FAMILIES_FACETS_FETCH_ERROR = 'RECOMMENDATIONS_FAMILIES_FACETS_FETCH_ERROR',
  RECOMMENDATIONS_FAMILIES_FACETS_FETCH_PENDING = 'RECOMMENDATIONS_FAMILIES_FACETS_FETCH_PENDING',
  RECOMMENDATIONS_FAMILIES_FACETS_FETCH_SUCCESS = 'RECOMMENDATIONS_FAMILIES_FACETS_FETCH_SUCCESS',

  REFERENCE_DATA_FETCH_ERROR = 'REFERENCE_DATA_FETCH_ERROR',
  REFERENCE_DATA_FETCH_PENDING = 'REFERENCE_DATA_FETCH_PENDING',
  REFERENCE_DATA_FETCH_SUCCESS = 'REFERENCE_DATA_FETCH_SUCCESS',

  RUDDERSTACK_ANONYMOUS_ID_SET = 'RUDDERSTACK_ANONYMOUS_ID_SET',

  SEARCH_BAR_ERROR = 'SEARCH_BAR_ERROR',
  SEARCH_BAR_PENDING = 'SEARCH_BAR_PENDING',
  SEARCH_BAR_SUCCESS = 'SEARCH_BAR_SUCCESS',

  SHIPPING_FETCH_ERROR = 'SHIPPING_FETCH_ERROR',
  SHIPPING_FETCH_PENDING = 'SHIPPING_FETCH_PENDING',
  SHIPPING_FETCH_SUCCESS = 'SHIPPING_FETCH_SUCCESS',

  SHOPIFY_PRODUCT_FETCH_ERROR = 'SHOPIFY_PRODUCT_FETCH_ERROR',
  SHOPIFY_PRODUCT_FETCH_PENDING = 'SHOPIFY_PRODUCT_FETCH_PENDING',
  SHOPIFY_PRODUCT_FETCH_SUCCESS = 'SHOPIFY_PRODUCT_FETCH_SUCCESS',

  SIGNUP_LOGIN_MODAL_OPEN = 'SIGNUP_LOGIN_MODAL_OPEN',
  SIGNUP_LOGIN_MODAL_CLOSE = 'SIGNUP_LOGIN_MODAL_CLOSE',

  SPECIES_FETCH_ERROR = 'SPECIES_FETCH_ERROR',
  SPECIES_FETCH_PENDING = 'SPECIES_FETCH_PENDING',
  SPECIES_FETCH_SUCCESS = 'SPECIES_FETCH_SUCCESS',

  STATE_FETCH_ERROR = 'STATE_FETCH_ERROR',
  STATE_FETCH_PENDING = 'STATE_FETCH_PENDING',
  STATE_FETCH_SUCCESS = 'STATE_FETCH_SUCCESS',

  STYLE_FETCH_ERROR = 'STYLE_FETCH_ERROR',
  STYLE_FETCH_PENDING = 'STYLE_FETCH_PENDING',
  STYLE_FETCH_SUCCESS = 'STYLE_FETCH_SUCCESS',

  TOURNAMENTS_FETCH_ERROR = 'TOURNAMENTS_FETCH_ERROR',
  TOURNAMENTS_FETCH_PENDING = 'TOURNAMENTS_FETCH_PENDING',
  TOURNAMENTS_FETCH_SUCCESS = 'TOURNAMENTS_FETCH_SUCCESS',
  TOURNAMENT_FETCH_ERROR = 'TOURNAMENT_FETCH_ERROR',
  TOURNAMENT_FETCH_PENDING = 'TOURNAMENT_FETCH_PENDING',
  TOURNAMENT_FETCH_SUCCESS = 'TOURNAMENT_FETCH_SUCCESS',
  TOURNAMENTS_CREATE_ERROR = 'TOURNAMENTS_CREATE_ERROR',
  TOURNAMENTS_CREATE_PENDING = 'TOURNAMENTS_CREATE_PENDING',
  TOURNAMENTS_CREATE_SUCCESS = 'TOURNAMENTS_CREATE_SUCCESS',

  USER_USER_PROFILE_FETCH_ERROR = 'USER_USER_PROFILE_FETCH_ERROR',
  USER_USER_PROFILE_FETCH_PENDING = 'USER_USER_PROFILE_FETCH_PENDING',
  USER_USER_PROFILE_FETCH_SUCCESS = 'USER_USER_PROFILE_FETCH_SUCCESS',

  USER_BRANDS_FETCH_ERROR = 'USER_BRANDS_FETCH_ERROR',
  USER_BRANDS_FETCH_PENDING = 'USER_BRANDS_FETCH_PENDING',
  USER_BRANDS_FETCH_SUCCESS = 'USER_BRANDS_FETCH_SUCCESS',

  USER_EXPERIMENT_GROUP_ID_READY = 'USER_EXPERIMENT_GROUP_ID_READY',
  USER_EXPERIMENT_IDS_SET = 'USER_EXPERIMENT_IDS_SET',

  USER_AMBASSADORS_FETCH_ERROR = 'USER_AMBASSADORS_FETCH_ERROR',
  USER_AMBASSADORS_FETCH_PENDING = 'USER_AMBASSADORS_FETCH_PENDING',
  USER_AMBASSADORS_FETCH_SUCCESS = 'USER_AMBASSADORS_FETCH_SUCCESS',

  USER_FISHING_REPORT_FETCH_ERROR = 'USER_FISHING_REPORT_FETCH_ERROR',
  USER_FISHING_REPORT_FETCH_PENDING = 'USER_FISHING_REPORT_FETCH_PENDING',
  USER_FISHING_REPORT_FETCH_SUCCESS = 'USER_FISHING_REPORT_FETCH_SUCCESS',
  USER_FISHING_REPORT_UPDATE_ERROR = 'USER_FISHING_REPORT_UPDATE_ERROR',
  USER_FISHING_REPORT_UPDATE_PENDING = 'USER_FISHING_REPORT_UPDATE_PENDING',
  USER_FISHING_REPORT_UPDATE_SUCCESS = 'USER_FISHING_REPORT_UPDATE_SUCCESS',
  USER_FISHING_REPORT_DELETE_ERROR = 'USER_FISHING_REPORT_DELETE_ERROR',
  USER_FISHING_REPORT_DELETE_PENDING = 'USER_FISHING_REPORT_DELETE_PENDING',
  USER_FISHING_REPORT_DELETE_SUCCESS = 'USER_FISHING_REPORT_DELETE_SUCCESS',

  USER_FETCH_ERROR = 'USER_FETCH_ERROR',
  USER_FETCH_PENDING = 'USER_FETCH_PENDING',
  USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS',

  USER_LISTS_FETCH_ERROR = 'USER_LISTS_FETCH_ERROR',
  USER_LISTS_FETCH_PENDING = 'USER_LISTS_FETCH_PENDING',
  USER_LISTS_FETCH_SUCCESS = 'USER_LISTS_FETCH_SUCCESS',
  USER_LISTS_CREATE_ERROR = 'USER_LISTS_CREATE_ERROR',
  USER_LISTS_CREATE_PENDING = 'USER_LISTS_CREATE_PENDING',
  USER_LISTS_CREATE_SUCCESS = 'USER_LISTS_CREATE_SUCCESS',
  USER_LISTS_UPDATE_ERROR = 'USER_LISTS_UPDATE_ERROR',
  USER_LISTS_UPDATE_PENDING = 'USER_LISTS_UPDATE_PENDING',
  USER_LISTS_UPDATE_SUCCESS = 'USER_LISTS_UPDATE_SUCCESS',
  USER_LISTS_DELETE_ERROR = 'USER_LISTS_DELETE_ERROR',
  USER_LISTS_DELETE_PENDING = 'USER_LISTS_DELETE_PENDING',
  USER_LISTS_DELETE_SUCCESS = 'USER_LISTS_DELETE_SUCCESS',
  USER_LISTS_ADD_PRODUCT_ERROR = 'USER_LISTS_ADD_PRODUCT_ERROR',
  USER_LISTS_ADD_PRODUCT_PENDING = 'USER_LISTS_ADD_PRODUCT_PENDING',
  USER_LISTS_ADD_PRODUCT_SUCCESS = 'USER_LISTS_ADD_PRODUCT_SUCCESS',
  USER_LISTS_REMOVE_PRODUCT_ERROR = 'USER_LISTS_REMOVE_PRODUCT_ERROR',
  USER_LISTS_REMOVE_PRODUCT_PENDING = 'USER_LISTS_REMOVE_PRODUCT_PENDING',
  USER_LISTS_REMOVE_PRODUCT_SUCCESS = 'USER_LISTS_REMOVE_PRODUCT_SUCCESS',

  USER_ORDERS_FETCH_ERROR = 'USER_ORDERS_FETCH_ERROR',
  USER_ORDERS_FETCH_PENDING = 'USER_ORDERS_FETCH_PENDING',
  USER_ORDERS_FETCH_SUCCESS = 'USER_ORDERS_FETCH_SUCCESS',

  USER_ORGANIZATIONS_FETCH_ERROR = 'USER_ORGANIZATIONS_FETCH_ERROR',
  USER_ORGANIZATIONS_FETCH_PENDING = 'USER_ORGANIZATIONS_FETCH_PENDING',
  USER_ORGANIZATIONS_FETCH_SUCCESS = 'USER_ORGANIZATIONS_FETCH_SUCCESS',

  USER_PRODUCTS_CREATE_ERROR = 'USER_PRODUCTS_CREATE_ERROR',
  USER_PRODUCTS_CREATE_PENDING = 'USER_PRODUCTS_CREATE_PENDING',
  USER_PRODUCTS_CREATE_SUCCESS = 'USER_PRODUCTS_CREATE_SUCCESS',
  USER_PRODUCTS_DELETE_ERROR = 'USER_PRODUCTS_DELETE_ERROR',
  USER_PRODUCTS_DELETE_PENDING = 'USER_PRODUCTS_DELETE_PENDING',
  USER_PRODUCTS_DELETE_SUCCESS = 'USER_PRODUCTS_DELETE_SUCCESS',
  USER_PRODUCTS_FETCH_ERROR = 'USER_PRODUCTS_FETCH_ERROR',
  USER_PRODUCTS_FETCH_PENDING = 'USER_PRODUCTS_FETCH_PENDING',
  USER_PRODUCTS_FETCH_SUCCESS = 'USER_PRODUCTS_FETCH_SUCCESS',

  USER_LIST_MODAL_CLOSE = 'USER_LIST_MODAL_CLOSE',
  USER_LIST_MODAL_OPEN = 'USER_LIST_MODAL_OPEN',

  USER_PREFERENCES_BRAND_SAVE = 'USER_PREFERENCES_BRAND_SAVE',
  USER_PREFERENCES_SPECIES_SAVE = 'USER_PREFERENCES_SPECIES_SAVE',
  USER_PREFERENCES_TECHNIQUE_SAVE = 'USER_PREFERENCES_TECHNIQUE_SAVE',
  USER_PREFERENCES_WATERBODY_SAVE = 'USER_PREFERENCES_WATERBODY_SAVE',

  USER_PROFILE_EVENT_NOTIFICATIONS_UPDATE_ERROR = 'USER_PROFILE_EVENT_NOTIFICATIONS_UPDATE_ERROR',
  USER_PROFILE_EVENT_NOTIFICATIONS_UPDATE_PENDING = 'USER_PROFILE_EVENT_NOTIFICATIONS_UPDATE_PENDING',
  USER_PROFILE_EVENT_NOTIFICATIONS_UPDATE_SUCCESS = 'USER_PROFILE_EVENT_NOTIFICATIONS_UPDATE_SUCCESS',
  USER_PROFILE_FETCH_ERROR = 'USER_PROFILE_FETCH_ERROR',
  USER_PROFILE_FETCH_PENDING = 'USER_PROFILE_FETCH_PENDING',
  USER_PROFILE_FETCH_SUCCESS = 'USER_PROFILE_FETCH_SUCCESS',
  USER_PROFILE_UPDATE_ERROR = 'USER_PROFILE_UPDATE_ERROR',
  USER_PROFILE_UPDATE_PENDING = 'USER_PROFILE_UPDATE_PENDING',
  USER_PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS',
  USER_PROFILE_FAVORITE_ERROR = 'USER_PROFILE_FAVORITE_ERROR',
  USER_PROFILE_FAVORITE_PENDING = 'USER_PROFILE_FAVORITE_PENDING',
  AMBASSADOR_FAVORITE_SUCCESS = 'AMBASSADOR_FAVORITE_SUCCESS',
  USER_PROFILE_UNFAVORITE_ERROR = 'USER_PROFILE_UNFAVORITE_ERROR',
  USER_PROFILE_UNFAVORITE_PENDING = 'USER_PROFILE_UNFAVORITE_PENDING',
  AMBASSADOR_UNFAVORITE_SUCCESS = 'AMBASSADOR_UNFAVORITE_SUCCESS',

  VARIANT_SELECTED = 'VARIANT_SELECTED',

  VIDEO_FETCH_ERROR = 'VIDEO_FETCH_ERROR',
  VIDEO_FETCH_PENDING = 'VIDEO_FETCH_PENDING',
  VIDEO_FETCH_SUCCESS = 'VIDEO_FETCH_SUCCESS',

  WATERBODY_AMBASSADORS_FETCH_ERROR = 'WATERBODY_AMBASSADORS_FETCH_ERROR',
  WATERBODY_AMBASSADORS_FETCH_PENDING = 'WATERBODY_AMBASSADORS_FETCH_PENDING',
  WATERBODY_AMBASSADORS_FETCH_SUCCESS = 'WATERBODY_AMBASSADORS_FETCH_SUCCESS',
  WATERBODY_EVENT_NOTIFICATIONS_UPDATE_ERROR = 'WATERBODY_EVENT_NOTIFICATIONS_UPDATE_ERROR',
  WATERBODY_EVENT_NOTIFICATIONS_UPDATE_PENDING = 'WATERBODY_EVENT_NOTIFICATIONS_UPDATE_PENDING',
  WATERBODY_EVENT_NOTIFICATIONS_UPDATE_SUCCESS = 'WATERBODY_EVENT_NOTIFICATIONS_UPDATE_SUCCESS',
  WATERBODY_FAVORITE_ERROR = 'WATERBODY_FAVORITE_ERROR',
  WATERBODY_FAVORITE_PENDING = 'WATERBODY_FAVORITE_PENDING',
  WATERBODY_FAVORITE_SUCCESS = 'WATERBODY_FAVORITE_SUCCESS',
  WATERBODY_FETCH_ERROR = 'WATERBODY_FETCH_ERROR',
  WATERBODY_FETCH_PENDING = 'WATERBODY_FETCH_PENDING',
  WATERBODY_FETCH_SUCCESS = 'WATERBODY_FETCH_SUCCESS',
  WATERBODY_FISHING_REPORTS_FETCH_ERROR = 'WATERBODY_FISHING_REPORTS_FETCH_ERROR',
  WATERBODY_FISHING_REPORTS_FETCH_PENDING = 'WATERBODY_FISHING_REPORTS_FETCH_PENDING',
  WATERBODY_FISHING_REPORTS_FETCH_SUCCESS = 'WATERBODY_FISHING_REPORTS_FETCH_SUCCESS',
  WATERBODY_FROM_PIN_FETCH_ERROR = 'WATERBODY_FROM_PIN_FETCH_ERROR',
  WATERBODY_FROM_PIN_FETCH_PENDING = 'WATERBODY_FROM_PIN_FETCH_PENDING',
  WATERBODY_FROM_PIN_FETCH_SUCCESS = 'WATERBODY_FROM_PIN_FETCH_SUCCESS',
  WATERBODY_RECOMMENDATIONS_COUNT_SET = 'WATERBODY_RECOMMENDATIONS_COUNT_SET',
  WATERBODY_RECOMMENDATIONS_FETCH_ERROR = 'WATERBODY_RECOMMENDATIONS_FETCH_ERROR',
  WATERBODY_RECOMMENDATIONS_FETCH_PENDING = 'WATERBODY_RECOMMENDATIONS_FETCH_PENDING',
  WATERBODY_RECOMMENDATIONS_FETCH_SUCCESS = 'WATERBODY_RECOMMENDATIONS_FETCH_SUCCESS',
  WATERBODY_REQUEST_ACTIVATION_ERROR = 'WATERBODY_REQUEST_ACTIVATION_ERROR',
  WATERBODY_REQUEST_ACTIVATION_PENDING = 'WATERBODY_REQUEST_ACTIVATION_PENDING',
  WATERBODY_REQUEST_ACTIVATION_SUCCESS = 'WATERBODY_REQUEST_ACTIVATION_SUCCESS',
  WATERBODY_RESET = 'WATERBODY_RESET',
  WATERBODY_TOURNAMENTS_FETCH_ERROR = 'WATERBODY_TOURNAMENTS_FETCH_ERROR',
  WATERBODY_TOURNAMENTS_FETCH_PENDING = 'WATERBODY_TOURNAMENTS_FETCH_PENDING',
  WATERBODY_TOURNAMENTS_FETCH_SUCCESS = 'WATERBODY_TOURNAMENTS_FETCH_SUCCESS',
  WATERBODY_UNFAVORITE_ERROR = 'WATERBODY_UNFAVORITE_ERROR',
  WATERBODY_UNFAVORITE_PENDING = 'WATERBODY_UNFAVORITE_PENDING',
  WATERBODY_UNFAVORITE_SUCCESS = 'WATERBODY_UNFAVORITE_SUCCESS',
  WATERBODY_WATERBODY_TECHNIQUES_FETCH_ERROR = 'WATERBODY_WATERBODY_TECHNIQUES_FETCH_ERROR',
  WATERBODY_WATERBODY_TECHNIQUES_FETCH_PENDING = 'WATERBODY_WATERBODY_TECHNIQUES_FETCH_PENDING',
  WATERBODY_WATERBODY_TECHNIQUES_FETCH_SUCCESS = 'WATERBODY_WATERBODY_TECHNIQUES_FETCH_SUCCESS',

  WINDOW_SET_LOADED = 'WINDOW_SET_LOADED',
  WINDOW_SET_SIZE = 'WINDOW_SET_WIDTH',

  WISHLIST_FETCH_ERROR = 'WISHLIST_FETCH_ERROR',
  WISHLIST_FETCH_PENDING = 'WISHLIST_FETCH_PENDING',
  WISHLIST_FETCH_SUCCESS = 'WISHLIST_FETCH_SUCCESS',
}
