import classNames from 'classnames';
import * as React from 'react';
import SvgLakes from '../svg/lakes';
import styles from './pins.less';

export const FILL_LOW_ACTIVITY = '#28424F';
export const FILL_MID_ACTIVITY = '#006B99';
export const FILL_HIGH_ACTIVITY = '#E84B25';
export const FILL_FEATURED = '#308800';
export const FILL_FAVORITE = '#F3A626';
export interface PinProps {
  selected?: boolean;
  favorite?: boolean;
  featured?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
  hasMedia?: boolean;
  fishingReportCount?: number;
}

export const formatFishingReportsCount = (num: number): string => {
  if (num >= 1000) {
    // Divide the number by 1000
    let formattedNumber = num / 1000;
    // If the number is less than 1100, show it as a whole number
    if (num < 1100) {
      return `${Math.floor(formattedNumber)}k`;
    } else {
      // Otherwise, show it with one decimal place
      formattedNumber = parseFloat(formattedNumber.toFixed(1));
      return `${formattedNumber}k`;
    }
  }
  return num.toString();
};

export const PinLowActivity = (props: PinProps) => {
  const { selected, onClick, className } = props;

  return (
    <div
      className={classNames(
        styles.pinWrapper,
        styles.pinWrapper__sm,
        {
          [styles.pinWrapper__selected]: selected,
        },
        className
      )}
      onClick={onClick}
    >
      <div className={classNames(styles.pin, styles.pin__sm, { [styles.pin__selected]: selected })}>
        <SvgLakes className={styles.svgLake} />
      </div>
    </div>
  );
};
PinLowActivity.width = 36;
PinLowActivity.height = 36;
